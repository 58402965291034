import React from 'react';
import styled from "styled-components";
import * as IconModule from "../../UI/Svg";
import {SvgProps} from "../../UI/Svg";
import {addZeroForward} from "../../utils";

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const Card = styled.div`
  background: #F3F3F3;
  border-radius: 20px;
  background: ${({theme}) => theme.colors.card};
  padding: 15px 15px 15px 17px;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 24px;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding: 28px 28px 30px 27px;
    max-width: calc(100% / 2 - (1/2 * 13px));
  }
  ${({theme}) => theme.mediaQueries.xl} {
    max-width: calc(100% / 4 - (3/4 * 13px));
  }
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  width: unset;
  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
  }
`

const IconWrapper = styled.div`
  width: 79px;
  height: 79px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 20px;
  background: ${({theme}) => theme.colors.primaryWrapper};
`
const NumberText = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 107%;
  color: ${({theme}) => theme.colors.primary};
  opacity: .2;
  display: none;
  ${({theme}) => theme.mediaQueries.md} {
    display: inline;
  }
`

const Desc = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 157%;
  max-width: 160px;
  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 85px;
  }
`
function Index({icon, desc, numb}: {icon: string, desc: string, numb: number}) {

    const Icon = Icons[icon];
    const iconElement = <Icon/>;
    return (
        <Card>
            <Header>
                <IconWrapper>
                    {iconElement}
                </IconWrapper>
                <NumberText>{addZeroForward(numb.toString())}</NumberText>
            </Header>
            <Desc>
                {desc}
            </Desc>
        </Card>
    );
}

export default Index;
