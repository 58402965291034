import styled from "styled-components";
import {HeadingTypes} from "./types";

const Heading = styled.h2<HeadingTypes>`
  font-style: normal;
  font-weight: 600;
  margin: 0;
  font-size: 30px;
  line-height: 107%;
  color: ${({theme}) => theme.colors.heading      };
  @media(min-width: 768px) {
    font-size: 48px;
  }
`

export default Heading;
