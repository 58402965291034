import styled from "styled-components";

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0 16px;
  @media(min-width: 1200px) {
    padding: 0;
  }
`

export default Container
