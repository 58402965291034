export const howWork: Array<{desc: string, icon: string}> = [
    {
        icon: 'HubIcon',
        desc: 'connect with people around you'
    },
    {
        icon: 'SpokeIcon',
        desc: 'do the great things together'
    },
    {
        icon: 'FlagIcon',
        desc: 'achieve your mutual goals'
    },
    {
        icon: 'DiversityIcon',
        desc: 'help other teams around you'
    },
]
