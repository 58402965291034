import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="32" height="36" viewBox="0 0 32 36" fill="none" {...props} >
            <path d="M7.05094 31.5H0.520313V10.4696H7.05094V31.5ZM3.78211 7.60081C1.69383 7.60081 0 5.87112 0 3.78284C1.4947e-08 2.77976 0.398471 1.81777 1.10775 1.10849C1.81704 0.399203 2.77903 0.000732422 3.78211 0.000732422C4.78519 0.000732422 5.74718 0.399203 6.45646 1.10849C7.16575 1.81777 7.56422 2.77976 7.56422 3.78284C7.56422 5.87112 5.86969 7.60081 3.78211 7.60081ZM31.493 31.5H24.9764V21.2625C24.9764 18.8227 24.9272 15.6938 21.581 15.6938C18.1856 15.6938 17.6653 18.3446 17.6653 21.0867V31.5H11.1417V10.4696H17.4052V13.3383H17.4966C18.3684 11.686 20.4982 9.94222 23.6756 9.94222C30.285 9.94222 31.5 14.2946 31.5 19.9477V31.5H31.493Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
