import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props} >
            <path d="M6.25 26.25V5H17.5L18 7.5H25V20H16.25L15.75 17.5H8.75V26.25H6.25ZM18.3125 17.5H22.5V10H15.9375L15.4375 7.5H8.75V15H17.8125L18.3125 17.5Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
