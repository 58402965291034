import React from 'react';
import styled from "styled-components";
import {Container} from "../components/TemplateComponents";
import {Heading} from "../UI/Heading";
import {DetailIcon} from "../UI/Svg";
import charity from '../assets/images/Charity.png'
import charityHeart from '../assets/images/CharityHeart.png'

const Section = styled.section`
  width: 100%;
  margin-top: 141px;
  padding: 0 16px;
  ${({theme}) => theme.mediaQueries.xl} {
    margin-top: 154px;
    padding: 0;
  }
`

const ContainerStyled = styled(Container)`
  background: ${({theme}) => theme.colors.charityBG};
  border-radius: 20px;
  position: relative;
  justify-content: flex-end;
  padding-top: 250px;
  max-height: 770px;
  ${({theme}) => theme.mediaQueries.md} {
    padding-top: 300px;
    justify-content: center;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    padding-top: 69px;
    justify-content: flex-end;
    padding-right: 93px;
    max-height: 468px;
  }
`

const Content = styled.div`
  text-align: center;
  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    text-align: left;
    display: block;
  }
`

const Desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 157%;
  margin-top: 25px;
  max-width: 366px;
`

const Details = styled.div`
  padding: 40px 40px 50px;
  box-shadow: 0 24px 64px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin-top: 45px;
  text-align: left;
  background: ${({theme}) => theme.colors.background};
`

const Header = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 157%;
    color: ${({theme}) => theme.colors.charity};
  }
`

const CharityImage = styled.img`
  position: absolute;
  max-width: 110%;
  left: 50%;
  top: 0;
  transform: translate(-50%, -35%);
  ${({theme}) => theme.mediaQueries.md} {

    max-width: 80%;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    max-width: 687px;
    transform: none;
    left: -95px;
    top: -37px;
  }
`

const CharityImageBefore = styled(CharityImage)`
  top: 2px;
  filter: blur(36px);
  ${({theme}) => theme.mediaQueries.xl} {
    filter: blur(72px);
  }
`

const HeartLeft = styled.img`
  position: absolute;
  filter: blur(6.5px);
  max-width: 81px;
  left: 0;
  top: -20px;
  transform: translate(-50%, -100%) rotate(-9.27deg);
  ${({theme}) => theme.mediaQueries.xl} {
    transform: rotate(-9.27deg);
    top: 8px;
    left: -90px;
    max-width: 177px;
  }
`
const HeartRight = styled.img`
  position: absolute;
  filter: blur(6.5px);
  right: -24px;
  top: 150px;
  transform: rotate(42.08deg);
  max-width: 92px;
  ${({theme}) => theme.mediaQueries.xl} {
    right: -102px;
    bottom: 34px;
    max-width: 192px;
  }
`
function CharityView() {
    return (
        <Section id='charity'>
            <ContainerStyled>
                <HeartLeft src={charityHeart}/>
                <HeartRight src={charityHeart}/>
                <CharityImageBefore src={charity}/>
                <CharityImage src={charity}/>
                <Content>
                    <Heading>Charity</Heading>
                    <Desc>The essencial thing that lies beneath our platform is helping people Every part of our
                        platform can become a charity foundation</Desc>
                    <Details>
                        <Header>
                            <DetailIcon/>
                            <span>Details</span>
                        </Header>
                        <Desc>Every participant and every team within our ecosystem can create their project and start
                            the crowd funding process for it.
                            <br/>
                            But the main term for all the participants of this platform is to give an a dequate share of
                            the profit generated within the platform for the charity</Desc>
                    </Details>
                </Content>
            </ContainerStyled>
        </Section>
    );
}

export default CharityView;
