import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="29" height="29" viewBox="0 0 29 29" fill="none" {...props} >
            <path d="M26.2164 4.3039C25.8012 3.95184 25.1491 3.90147 24.4749 4.17215H24.4738C23.7647 4.45668 4.4016 12.7621 3.61335 13.1014C3.46998 13.1513 2.21785 13.6185 2.34683 14.6591C2.46197 15.5974 3.46832 15.986 3.59121 16.0308L8.51391 17.7164C8.8405 18.8035 10.0445 22.8146 10.3107 23.6715C10.4768 24.2056 10.7475 24.9075 11.2219 25.052C11.6381 25.2125 12.0522 25.0658 12.3201 24.8555L15.3298 22.0639L20.1882 25.853L20.3039 25.9222C20.6339 26.0683 20.9499 26.1414 21.2516 26.1414C21.4847 26.1414 21.7083 26.0977 21.922 26.0102C22.6499 25.7113 22.941 25.0177 22.9715 24.9391L26.6006 6.07581C26.822 5.06835 26.5142 4.55576 26.2164 4.3039ZM12.8554 18.3906L11.1947 22.819L9.5341 17.2835L22.2657 7.87318L12.8554 18.3906Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
