import { Colors } from "./types";

export const baseColors = {
    black: '#000',
    background: '#fff',
    white: '#fff',
    text: 'rgba(0,0,0,.7)',
    primary: '#7053F6',
    primaryWrapper: 'rgba(112,83,246,0.1)',
    border: 'rgba(0,0,0,.1)',
    borderLight: 'rgba(255,255,255,.1)',
    heading: '#07132D',
    card: '#F3F3F3',
    charityBG: '#F9D8C3',
    charity: '#CC9E81',
    borderGray: '#D3D3D3',
    placeholder: '#ADADAD',
};


export const lightColors: Colors = {
    ...baseColors,
};
