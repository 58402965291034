import React from 'react';
import styled from "styled-components";
import {InputProps} from "./types";

const InputBlock = styled.div`
  position: relative;
  width: 100%;
  max-width: 310px;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  outline: 1px solid ${({theme}) => theme.colors.borderGray};
  border-radius: 100px;
  padding: 20px 20px 19px;
  gap: 12px;
`

const InputStyled = styled.input`
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  &::placeholder {
    color: ${({theme}) => theme.colors.placeholder};
    font-weight: 600;
    font-size: 14px;
    line-height: 107%;
  }
`

function Input({onChange, value, type, placeholder, iconLeft, ...props}: InputProps) {
    return (
        <InputBlock {...props}>
            {iconLeft}
            <InputStyled
                         type={type}
                         value={value}
                         placeholder={placeholder}
                         onChange={event => onChange(event.target.value)}/>
        </InputBlock>
    );
}

export default Input;
