import React from 'react';
import styled from "styled-components";
import {Container} from "../components/TemplateComponents";
import {Button, EButtonVariants} from "../UI/Button";
import main from '../assets/images/Main.png'
import bgLeft from '../assets/images/bgLeft.png'
import mobileMain from '../assets/images/MobileMain.png'
import mobileBg from '../assets/images/MobileBg.png'

const Section = styled.section`
  width: 100%;
  position: relative;
  background-image: url("${mobileBg}");
  background-repeat: no-repeat;
  background-size: 100% auto; 
  background-position: center center;
  ${({theme}) => theme.mediaQueries.md} {
    background-image: url("${main}");
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: top right;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    height: 100vh;
    background-size: contain;
    background-position: top right;
  }
`

const ContainerStyled = styled(Container)`
  padding-top: 85px;
  ${({theme}) => theme.mediaQueries.md} {
    padding-top: 165px;
  }
`

const InfoBlock = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

const Title = styled.h1`
  max-width: 635px;
  font-weight: 600;
  font-size: 44px;
  line-height: 107%;
  color: ${({theme}) => theme.colors.heading};
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 64px;
  }
`

const Subtitle = styled.h2`
  margin-top: 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  color: ${({theme}) => theme.colors.primary};
  text-align: center;
  ${({theme}) => theme.mediaQueries.md} {
    text-align: left;
    font-size: 18px;
  }
`

const Desc = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  margin-top: 10px;
  max-width: 362px;
  text-align: center;
  ${({theme}) => theme.mediaQueries.md} {
    text-align: left;
    font-size: 16px;
    margin-top: 36px;
  }
`

const ButtonStyled = styled(Button)`
  max-width: 160px;
  padding: 19px 32px;
  margin: 42px auto 0;
  ${({theme}) => theme.mediaQueries.md} {
    margin: 60px 0 0;
    max-width: 180px;
    padding: 22px 30px;
  }
`

const LeftImage = styled.img`
  position: absolute;
  left: -191px;
  top: 518px;
  transform: rotateZ(83.29deg);
  max-width: 312px;
  height: auto;
  filter: blur(27px);
  display: none;
  ${({theme}) => theme.mediaQueries.xl} {
    display: inline;
  }
`

const MobileImg = styled.img`
  display: inline;
  width: 100%;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

function MainView() {
    return (
        <Section id='home'>
            <ContainerStyled>
                <InfoBlock>
                    <Title>Digital infrastructure</Title>
                    <MobileImg src={mobileMain} alt='Mobile'/>
                    <Subtitle>fully compliant with the WEB3 network generation</Subtitle>
                    <Desc>It will be implemented with the assistance and support of the Association WEB 3 of Ukraine & Ukrainian Volunteers Association</Desc>
                    <ButtonStyled variant={EButtonVariants.Primary}>More details</ButtonStyled>
                </InfoBlock>
            </ContainerStyled>
            <LeftImage src={bgLeft}/>
        </Section>
    );
}

export default MainView;
