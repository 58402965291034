import React from 'react';
import ResetCSS from "./styles/ResetCSS";
import GlobalStyle from "./styles/Global";
import Navbar from "./components/Navbar";
import {CharityView, MainView, HowWorkVIew, RequestView, WhatIsView, ContactView} from "./views";

function App() {
    return (
        <>
            <ResetCSS/>
            <GlobalStyle/>
            <Navbar/>
            <MainView/>
            <HowWorkVIew/>
            <CharityView/>
            <ContactView/>
            <RequestView/>
            <WhatIsView/>
        </>
    );
}

export default App;
