import React, {useState} from 'react';
import styled from "styled-components";
import {Heading} from "../UI/Heading";
import {Container} from "../components/TemplateComponents";
import {Button, EButtonVariants} from "../UI/Button";
import {Input} from "../UI/Input";
import {EmailIcon, UserIcon} from "../UI/Svg";

const Section = styled.section`
  width: 100%;
  margin-top: 120px;
  position: relative;
  z-index: 10;
  padding: 0 16px;
  ${({theme}) => theme.mediaQueries.xl} {
    padding: 0;
  }
`

const HeadingStyled = styled(Heading)`
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  ${({theme}) => theme.mediaQueries.md} {
    max-width: 592px;
  }
`

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 157%;
  text-align: center;
  margin: 31px auto 0;
`

const ContainerStyled = styled(Container)`
  background: ${({theme}) => theme.colors.background};
  box-shadow: 0 24px 94px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  margin-top: 56px;
  padding: 36px 29px 43px;
  justify-content: center;
  gap: 37px;
  margin-bottom: -113px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  ${({theme}) => theme.mediaQueries.xl} {
    flex-direction: row;
    margin-top: 67px;
    padding: 74px;
  }
`

const ButtonStyled = styled(Button)`
  max-width: unset;
  padding: 22px 30px;
  ${({theme}) => theme.mediaQueries.md} {
    max-width: 180px;
  }
`

const Inputs = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  ${({theme}) => theme.mediaQueries.md} {
    width: unset;
    flex-direction: row;
    & > div {
      min-width: 310px;
    }
  }
`
function RequestView() {
    const [data, setData] = useState<{name: string, email: string}>({name: '', email: ''})

    const onChange = (val: string, key: string) => setData(prevState => ({
        ...prevState,
        [key]: val
    }))

    return (
        <Section id='request'>
            <HeadingStyled>Schedule a zoom call with us for futher details</HeadingStyled>
            <Subtitle>That will be an hour session with our team to discuss the whole project and cooperation</Subtitle>
            <ContainerStyled>
                <Inputs>
                    <Input type='string' iconLeft={<UserIcon/>} onChange={val => onChange(val, 'name')} value={data.name} placeholder={'Full Name'} />
                    <Input type='mail' iconLeft={<EmailIcon/>} onChange={val => onChange(val, 'email')} value={data.email} placeholder={'Email'} />
                </Inputs>
                <ButtonStyled variant={EButtonVariants.Primary}>More details</ButtonStyled>
            </ContainerStyled>
        </Section>
    );
}

export default RequestView;
