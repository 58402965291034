import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {Container} from "../TemplateComponents";
import logo from '../../assets/images/logo.svg'
import {Button, EButtonVariants} from "../../UI/Button";

const Navbar = styled.div<{ isScrolled: boolean }>`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: ${({theme, isScrolled}) => isScrolled ? theme.colors.background : 'transparent'};
`

const ContainerStyled = styled(Container)`
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`
const List = styled.div`
  display: none;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  ${({theme}) => theme.mediaQueries.xl} {
    gap: 39px;
    display: flex;
  }
`

const NavItem = styled.a<{ isActive: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  text-decoration: none;
  padding: 0 6px;
  color: ${({theme}) => theme.colors.black};
  opacity: ${({isActive}) => isActive ? 1 : .4};
  position: relative;
  white-space: nowrap;

  &:before {
    content: '';
    display: ${({isActive}) => isActive ? 'block' : 'none'};
    position: absolute;
    top: 0;
    height: 100%;
    width: 3px;
    left: 0;
    background: ${({theme}) => theme.colors.primary};
  }
  ${({theme}) => theme.mediaQueries.xl} {
    &:before {
      content: '';
      display: ${({isActive}) => isActive ? 'block' : 'none'};
      position: absolute;
      top: -31px;
      width: 100%;
      height: 3px;
      left: 50%;
      transform: translateX(-50%);
      background: ${({theme}) => theme.colors.primary};
    }
  }
`

const Logo = styled.a`
  img {
    max-width: 155px;
    height: auto;
  }

  ${({theme}) => theme.mediaQueries.xl} {
    img {
      max-width: 200px;
    }
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.xl} {
    gap: 122px;
  }
`
const MobileButton = styled.div<{ isShow: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-start;
  position: relative;
  width: 26px;
  min-height: 10px;

  span {
    width: 26px;
    height: 3px;
    background: ${({theme}) => theme.colors.primary};
    position: absolute;
    left: 0;
    top: 0;

    ${({isShow}) => isShow ? `
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotateZ(45deg);` : ''}
    &:nth-child(2) {
      left: unset;
      top: unset;
      right: 0;
      bottom: 0;
      opacity: ${({isShow}) => isShow ? 1 : 0.4};
      width: ${({isShow}) => isShow ? '26px' : '10px'};
      ${({isShow}) => isShow ? `
      right: unset;
      bottom: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) rotateZ(135deg);` : ''}
    }
  }

  ${({theme}) => theme.mediaQueries.xl} {
    display: none;
  }
`
const MobileMenu = styled.div<{ isShow: boolean }>`
  visibility: ${({isShow}) => isShow ? 'visible' : 'hidden'};
  opacity: ${({isShow}) => isShow ? '1' : '0'};
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${({theme}) => theme.colors.background};
  backdrop-filter: blur(22px);
  z-index: 100;
`

const MobileNav = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin-top: 105px;
  flex-direction: column;
  padding-left: 15px;
  gap: 25px;
`

const ids = ['home', 'how-work', 'charity', 'what-is-p4p']


function Index() {
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
    const [elementsOffsets, setElementsOffsets] = useState<(number | null)[]>([null, null, null, null]);
    const [activeElement, setActiveElement] = useState<number>(0);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const onScrollHandler = useCallback(() => {
        const windowOffset = window.scrollY;
        const currentActive = elementsOffsets.reduce<number>((acc, item, id) => {
            if (item !== null) {
                if (item <= windowOffset) {
                    acc = id
                }
            }
            return acc
        }, 0);
        setIsScrolled(windowOffset > 0)
        setActiveElement(currentActive)
    }, [elementsOffsets])

    useEffect(() => {
        const elements = ids.map((id) => document.getElementById(id))
        const offsets = elements.map((el) => el ? el.offsetTop : null)
        setElementsOffsets(offsets)
        setIsScrolled(window.scrollY > 0)
    }, [])
    useEffect(() => {
        window.addEventListener('scroll', onScrollHandler)
        return () => {
            window.removeEventListener('scroll', onScrollHandler)
        }
    }, [onScrollHandler])

    return (
        <>
            <Navbar isScrolled={isScrolled}>
                <ContainerStyled>
                    <Logo href='/#home'>
                        <img src={logo} alt=""/>
                    </Logo>
                    <Right>
                        <List>
                            <NavItem isActive={activeElement === 0} href='/#home'>Home</NavItem>
                            <NavItem isActive={activeElement === 1} href='/#how-work'>How work</NavItem>
                            <NavItem isActive={activeElement === 2} href='/#charity'>Charity</NavItem>
                            <NavItem isActive={activeElement === 3} href='/#what-is-p4p'>What is P4P</NavItem>
                        </List>
                        <Button as='a' href='/#request' variant={EButtonVariants.Border}>Request us</Button>

                        <MobileButton onClick={() => setIsShowMenu(prevState => !prevState)} isShow={isShowMenu}>
                            <span/>
                            <span/>
                        </MobileButton>
                    </Right>
                </ContainerStyled>
            </Navbar>

            <MobileMenu isShow={isShowMenu}>
                <MobileNav>
                    <NavItem onClick={() => setIsShowMenu(false)} isActive={activeElement === 0} href='/#home'>Home</NavItem>
                    <NavItem onClick={() => setIsShowMenu(false)} isActive={activeElement === 1} href='/#how-work'>How work</NavItem>
                    <NavItem onClick={() => setIsShowMenu(false)} isActive={activeElement === 2} href='/#charity'>Charity</NavItem>
                    <NavItem onClick={() => setIsShowMenu(false)} isActive={activeElement === 3} href='/#what-is-p4p'>What is P4P</NavItem>
                </MobileNav>
            </MobileMenu>
        </>
    );
}

export default Index;
