export function sleep(ms: number) {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    });
}

export async function scrollToElement(event: any, href: string, isRedirect = false) {
    if (isRedirect) {
        await sleep(300)
    }
    const id = href.split('#')[1]
    if (id) {
        const element = document.getElementById(id);
        if (element) {
            const top = element.offsetTop;
            window.scrollTo({
                top,
                behavior: 'smooth',
            })
        }
    }
}

export const addZeroForward = (string: string, needLength: number = 2) => `${'0'.repeat(needLength - string.length)}${string}`;


export function bufferToHex(buffer: Uint8Array) {
    return Array
        .from(buffer)
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
}


export function renderDate(timestamp: string, withTime?: boolean, withSeconds?: boolean): string {
    const date = new Date(parseInt(timestamp, 10) * 1000)
    if (withTime) {
        return (`${addZeroForward(date.getDate().toString())}.${addZeroForward((date.getMonth() + 1).toString())}.${date.getFullYear()}, ${addZeroForward(date.getHours().toString())}:${addZeroForward(date.getMinutes().toString())}${withSeconds ? `:${addZeroForward(date.getSeconds().toString())}` : ''}`)
    }
    return `${addZeroForward(date.getDate().toString())}.${addZeroForward((date.getMonth() + 1).toString())}.${date.getFullYear()}`
}
