import React from 'react';
import styled from "styled-components";
import {Heading} from "../UI/Heading";
import {Container} from "../components/TemplateComponents";
import HowComponent from "../components/HowComponent";
import {howWork} from "../config/howWork";


const Section = styled.section`
  width: 100%;
  margin-top: 87px;
  ${({theme}) => theme.mediaQueries.xl} {
    margin-top: -44px;
  }
`

const HeadingStyled = styled(Heading)`
  text-align: center;
`

const List = styled(Container)`
  justify-content: center;
  gap: 13px;
  align-content: center;
  align-items: stretch;
  margin-top: 66px;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.md} {
    flex-wrap: wrap;
    flex-direction: row;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    flex-wrap: nowrap;
  }
`
function HowWorkVIew() {
    return (
        <Section id='how-work' >
            <HeadingStyled>How does it work</HeadingStyled>
            <List>
                {
                    howWork.map((item, id) => <HowComponent key={`How-${id}`} {...item} numb={id + 1}/>)
                }
            </List>
        </Section>
    );
}

export default HowWorkVIew;
