import React from 'react';
import {ThemeProvider} from 'styled-components';
import {light} from "./theme";


interface Props {
    children: React.ReactNode
}

function Providers({children}: Props) {
    return (
        <ThemeProvider theme={light}>
            {children}
        </ThemeProvider>
    );
}

export default Providers;
