import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props} >
            <path d="M24.7551 0H11.2449C9.38081 0 7.86993 1.51088 7.86993 3.375V32.625C7.86993 34.4891 9.38081 36 11.2449 36H24.7551C26.6192 36 28.1301 34.4891 28.1301 32.625V3.375C28.1301 1.51088 26.6192 0 24.7551 0ZM25.8801 32.625C25.8801 33.246 25.3761 33.75 24.7551 33.75H11.2449C10.6239 33.75 10.1199 33.246 10.1199 32.625V3.375C10.1199 2.754 10.6239 2.25 11.2449 2.25H24.7551C25.3761 2.25 25.8801 2.754 25.8801 3.375V32.625ZM18.0017 28.1312C16.7614 28.1312 15.7573 29.1364 15.7573 30.375C15.7573 31.6136 16.7619 32.6194 18.0017 32.6194C19.242 32.6194 20.2461 31.6137 20.2461 30.375C20.2461 29.1364 19.242 28.1312 18.0017 28.1312ZM20.2517 3.375H15.7517C15.1307 3.375 14.6267 3.879 14.6267 4.5C14.6267 5.121 15.1307 5.625 15.7517 5.625H20.2517C20.8727 5.625 21.3767 5.121 21.3767 4.5C21.3767 3.879 20.8727 3.375 20.2517 3.375Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
