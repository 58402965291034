import React from 'react';
import styled from "styled-components";
import {Container} from "../components/TemplateComponents";
import {
    DiscordIcon,
    LinkedInIcon,
    LocationIcon,
    MailIcon,
    ProfileIcon,
    SmartphoneIcon,
    TelegramIcon,
    TwitterIcon
} from "../UI/Svg";

const ContainerStyled = styled(Container)`
  margin-top: 274px;
  gap: 24px;
  flex-wrap: wrap;
  ${({theme}) => theme.mediaQueries.lg} {
    flex-wrap: nowrap;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: calc(100% / 2 - 12px);
  }
  ${({theme}) => theme.mediaQueries.lg} {
    max-width: calc(100% / 3 - (3 / 2 * 12px));
  }
`
const Title = styled.h4`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: ${({theme}) => theme.colors.heading};
`

const SocialItem = styled.a`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
`

const WrapperIcon = styled.span`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: 50%;

  svg {
    width: 24px;
    height: 24px;
  }
`

function ContactView() {
    return (
        <ContainerStyled>
            <Block>
                <Title>Contacts</Title>
                <SocialItem href='mailto:contact@people4people.co' target='_blank' rel='noreferrer'>
                    <WrapperIcon><MailIcon/></WrapperIcon> contact@people4people.co
                </SocialItem>
                <SocialItem href='https://discord.gg/bcNARuQu' target='_blank' rel='noreferrer'>
                    <WrapperIcon><DiscordIcon/></WrapperIcon>
                    #people4people
                </SocialItem>
                <SocialItem href='https://twitter.com/People4PeopleCo?t=sE4C2zBNZlCxyDOiIY19Uw&s=09' target='_blank' rel='noreferrer'>
                    <WrapperIcon><TwitterIcon/></WrapperIcon>
                    @People4PeopleCo
                </SocialItem>
                <SocialItem href='https://t.me/PeopleFor4People' target='_blank' rel='noreferrer'>
                    <WrapperIcon><TelegramIcon/></WrapperIcon>
                    @PeopleFor4People
                </SocialItem>
                <SocialItem href='https://www.linkedin.com/company/peoplefor4people/' target='_blank' rel='noreferrer'>
                    <WrapperIcon><LinkedInIcon/></WrapperIcon>
                    peoplefor4people
                </SocialItem>
            </Block>
            <Block>
                <Title>Mykhailo Romanenko</Title>
                <SocialItem>
                    <WrapperIcon><ProfileIcon/></WrapperIcon> CEO
                </SocialItem>
                <SocialItem href='tel:+380661739613' target='_blank' rel='noreferrer'>
                    <WrapperIcon><SmartphoneIcon/></WrapperIcon>
                    +380661739613
                </SocialItem>
                <SocialItem target='_blank' rel='noreferrer'>
                    <WrapperIcon><LocationIcon/></WrapperIcon>
                    42B Velika Vasylkivska street, Kiev, Ukraine
                </SocialItem>
                <SocialItem href='https://www.linkedin.com/in/mikhail-romanenko-0189b3153' target='_blank' rel='noreferrer'>
                    <WrapperIcon><LinkedInIcon/></WrapperIcon>
                    mikhail-romanenko
                </SocialItem>
            </Block>
            <Block>
                <Title>Alexander Tomas</Title>
                <SocialItem>
                    <WrapperIcon><ProfileIcon/></WrapperIcon> CTO
                </SocialItem>
                <SocialItem>
                    <WrapperIcon><LocationIcon/></WrapperIcon>
                    26 Jean Jaurès Street, 06 400 CANNES, France
                </SocialItem>
                <SocialItem href='https://www.linkedin.com/in/alexandre-thomas-57620479' target='_blank' rel='noreferrer'>
                    <WrapperIcon><LinkedInIcon/></WrapperIcon>
                    alexandre-thomas
                </SocialItem>
            </Block>
        </ContainerStyled>
    );
}

export default ContactView;
