import styled from "styled-components";
import {ButtonProps} from "./types";


const Button = styled.button<ButtonProps>`
  padding: 10px 23px;
  border: ${({theme, variant}) => theme.button[variant].border};
  outline: none;
  width: 100%;
  max-width: 137px;
  white-space: nowrap;
  background: ${({theme, variant}) => theme.button[variant].background};
  font-style: normal;
  text-align: center;
  color: ${({theme, variant}) => theme.button[variant].color};
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  & > * {
    z-index: 1;
  }

  svg path {
    transition: all .25s ease;
  }

  &:not(:disabled):hover {
    svg path {
    }
  }

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
  ${({theme}) => theme.mediaQueries.md} {
    font-weight: 600;
    font-size: 14px;
    line-height: 107%;
    max-width: 137px;
    padding: 14px 30px;
  }
`;

export default Button
