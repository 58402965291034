import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props} >
            <path d="M18 3C12.195 3 7.5 7.695 7.5 13.5C7.5 21.375 18 33 18 33C18 33 28.5 21.375 28.5 13.5C28.5 7.695 23.805 3 18 3ZM18 17.25C17.0054 17.25 16.0516 16.8549 15.3483 16.1517C14.6451 15.4484 14.25 14.4946 14.25 13.5C14.25 12.5054 14.6451 11.5516 15.3483 10.8483C16.0516 10.1451 17.0054 9.75 18 9.75C18.9946 9.75 19.9484 10.1451 20.6517 10.8483C21.3549 11.5516 21.75 12.5054 21.75 13.5C21.75 14.4946 21.3549 15.4484 20.6517 16.1517C19.9484 16.8549 18.9946 17.25 18 17.25Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
