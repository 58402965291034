import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="37" height="37" viewBox="0 0 37 37"fill="none" {...props} >
            <path d="M18.5 31.3864L3.97208 13.95L8.24836 5.39746H28.7516L33.0279 13.95L18.5 31.3864ZM14.3388 12.8846H22.6611L20.0406 7.64353H16.9593L14.3388 12.8846ZM17.3769 26.5371V15.1307H7.89987L17.3769 26.5371ZM19.623 26.5371L29.1001 15.1307H19.623V26.5371ZM25.1578 12.8846H29.9611L27.3406 7.64353H22.5372L25.1578 12.8846ZM7.03885 12.8846H11.8422L14.4627 7.64353H9.65938L7.03885 12.8846Z" fill="white"/>
        </Svg>
    );

export default Icon;
