import {ButtonTheme, EButtonVariants} from "./types";
import {lightColors} from "../../theme/colors";


export const light: ButtonTheme = {
    [EButtonVariants.Border]: {
        color: lightColors.black,
        background: `transparent`,
        border: `1.5px solid ${lightColors.border}`
    },
    [EButtonVariants.Primary]: {
        color: lightColors.white,
        border: `1.5px solid ${lightColors.primary}`,
        background: lightColors.primary,
    },
};
