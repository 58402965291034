import React from 'react';
import styled from "styled-components";
import {Container} from "../components/TemplateComponents";
import {DiamondIcon} from "../UI/Svg";
import Footer from "../components/Footer";


const Section = styled.section`
  width: 100%;
  background: linear-gradient(249.04deg, #5835C7 13.24%, #040A22 96.86%), #7053F6;
  padding-top: 181px;
  position: relative;
  z-index: 9;
`

const ContainerStyled = styled(Container)`
  position: relative;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.xl} {
    min-height: 860px;
  }
`

const CircleBG = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 860px;
  display: none;

  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
  }

  & > div {
    padding: 137px;
    border: 2px solid rgba(255, 255, 255, 0.1);

    & > div {
      border-radius: 50%;
      padding: 105px;
      border: 2px solid rgba(255, 255, 255, 0.3);

      & > div {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
  ${({theme}) => theme.mediaQueries.xl} {
    display: flex;
  }
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 48px;
  position: absolute;
  width: 100%;
  max-width: 528px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 107%;
  text-align: center;
  color: ${({theme}) => theme.colors.white};
`

const TitleMobile = styled(Title)`
  display: inline;
  position: relative;
  transform: none;
  top: unset;
  left: unset;
  font-size: 30px;
  text-align: center;
  max-width: 311px;
  margin: 0 auto 23px;
  ${({theme}) => theme.mediaQueries.xl} {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin-top: 15px;
  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: row;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    width: unset;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 72px;
    &:nth-child(3) {
      gap: 306px;
      margin-left: 20px;
    }
    &:nth-child(4) {
      gap: 570px;
      margin-right: 100px;
    }
    &:nth-child(5) {
      margin-top: 128px;
      gap: 659px;
      margin-left: 28px;
    }
    &:nth-child(6) {
      margin-top: 103px;
      gap: 411px;
    }
  }
`

const Item = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  &:nth-child(1) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    width: unset;
    flex-direction: row;
    &:nth-child(1) {
      flex-direction: row;
    }
    &:nth-child(3) {
      justify-content: flex-start;
    }
  }
`

const IconWrapper = styled.div`
  min-width: 79px;
  min-height: 79px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2.5px);
`

const ItemText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
  text-align: left;
  color: ${({theme}) => theme.colors.white};
  white-space: nowrap;
  ${({theme}) => theme.mediaQueries.xl} {
    &:nth-child(1) {
      text-align: right;
    }
    &:nth-child(2) {
      text-align: left;
    }
  }
`
function WhatIsView() {
    return (
        <Section id='what-is-p4p'>
            <ContainerStyled>
                <CircleBG>
                    <div>
                        <div>
                            <Title>p4p ecosystem consists of a symbiosis of services</Title>
                            <div/>
                        </div>
                    </div>
                </CircleBG>
                <TitleMobile>p4p ecosystem consists of a symbiosis of services</TitleMobile>
                <Row>
                    <Item>
                        <ItemText>p4p connector new <br/> generation messenger</ItemText>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                    </Item>
                    <Item>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                        <ItemText>crowdfunding platform based <br/> on the blockchain technology</ItemText>
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <ItemText>Our own crypto exchange <br/> with a convenient exchanger</ItemText>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                    </Item>
                    <Item>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                        <ItemText>P2E game <br/> development</ItemText>
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <ItemText>custom helper <br/> feed</ItemText>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                    </Item>
                    <Item>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                        <ItemText>Browser cloud <br/> storage and hosting</ItemText>
                    </Item>
                </Row>
                <Row>
                    <Item>
                        <ItemText>Our own learning <br/> platform</ItemText>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                    </Item>
                    <Item>
                        <IconWrapper><DiamondIcon/></IconWrapper>
                        <ItemText>Interactive road <br/> map reporter</ItemText>
                    </Item>
                </Row>
            </ContainerStyled>
            <Footer/>
        </Section>
    );
}

export default WhatIsView;
