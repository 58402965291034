import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="23" height="17" viewBox="0 0 23 17" fill="none" {...props} >
            <path d="M18.9306 1.89599C17.5475 1.30432 16.0644 0.868394 14.5136 0.618723C14.4854 0.613905 14.4572 0.625946 14.4426 0.650031C14.2519 0.966332 14.0406 1.37897 13.8926 1.70331C12.2247 1.4705 10.5653 1.4705 8.93161 1.70331C8.78362 1.37176 8.56466 0.966332 8.37305 0.650031C8.3585 0.62675 8.33029 0.614708 8.30205 0.618723C6.75216 0.867596 5.26899 1.30352 3.88503 1.89599C3.87305 1.90081 3.86278 1.90884 3.85597 1.91927C1.04271 5.83778 0.272037 9.65998 0.650101 13.4348C0.651812 13.4532 0.662931 13.4709 0.678328 13.4821C2.53444 14.753 4.3324 15.5245 6.09697 16.0359C6.12521 16.0439 6.15513 16.0343 6.1731 16.0126C6.59051 15.4811 6.96259 14.9208 7.28162 14.3315C7.30045 14.297 7.28247 14.256 7.244 14.2424C6.65381 14.0337 6.09183 13.7792 5.55125 13.4902C5.50849 13.4669 5.50507 13.4099 5.5444 13.3826C5.65816 13.3031 5.77195 13.2204 5.88057 13.1369C5.90023 13.1217 5.92761 13.1185 5.95072 13.1281C9.50211 14.6398 13.3469 14.6398 16.8564 13.1281C16.8795 13.1177 16.9069 13.1209 16.9274 13.1361C17.0361 13.2196 17.1498 13.3031 17.2644 13.3826C17.3038 13.4099 17.3012 13.4669 17.2584 13.4902C16.7179 13.7848 16.1559 14.0337 15.5648 14.2416C15.5264 14.2553 15.5092 14.297 15.5281 14.3315C15.8539 14.92 16.226 15.4803 16.6357 16.0118C16.6528 16.0343 16.6836 16.0439 16.7119 16.0359C18.485 15.5245 20.2829 14.753 22.1391 13.4821C22.1553 13.4709 22.1656 13.454 22.1673 13.4356C22.6198 9.07149 21.4094 5.28064 18.9589 1.92007C18.9529 1.90884 18.9426 1.90081 18.9306 1.89599ZM7.81196 11.1363C6.74275 11.1363 5.86175 10.2211 5.86175 9.09719C5.86175 7.97325 6.72566 7.05807 7.81196 7.05807C8.90679 7.05807 9.77926 7.98129 9.76215 9.09719C9.76215 10.2211 8.89823 11.1363 7.81196 11.1363ZM15.0225 11.1363C13.9534 11.1363 13.0724 10.2211 13.0724 9.09719C13.0724 7.97325 13.9362 7.05807 15.0225 7.05807C16.1174 7.05807 16.9898 7.98129 16.9728 9.09719C16.9728 10.2211 16.1174 11.1363 15.0225 11.1363Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
