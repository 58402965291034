import React from 'react';
import styled from "styled-components";
import {Container} from "../TemplateComponents";
import logo from '../../assets/images/LogoWhite.svg'
import {Button, EButtonVariants} from "../../UI/Button";


const ContainerStyled = styled(Container)`
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 20px 16px;
  flex-direction: column;
  gap: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    padding: 20px 0;
    flex-wrap: nowrap;
  }
`
const List = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  @media (min-width: 768px) {
    gap: 39px;
  }
`

const NavItem = styled.a`
  text-decoration: none;
  padding: 0 6px;
  color: ${({theme}) => theme.colors.white};
  opacity: .4;
  position: relative;
  white-space: nowrap;

  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
`

const Logo = styled.a`
  img {
    max-width: 200px;
    height: auto;
  }
`


const Copyright = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 107%;
  color: ${({theme}) => theme.colors.white};
  opacity: 0.4;
`

const ButtonStyled = styled(Button)`
  color: ${({theme}) => theme.colors.white};
  outline-color: ${({theme}) => theme.colors.borderLight};
`
function Index() {

    return (
        <ContainerStyled>
            <Logo href='/#home'>
                <img src={logo} alt=""/>
            </Logo>
            <List>
                <Copyright>Copyright {(new Date()).getFullYear()}</Copyright>
                <Copyright>All right reserved</Copyright>
            </List>
            <List>
                <NavItem href='/'>Policy Privacy</NavItem>
                <NavItem href='/'>Documents</NavItem>
            </List>
            <ButtonStyled as='a' href='/#request' variant={EButtonVariants.Border}>Request us</ButtonStyled>
        </ContainerStyled>
    );
}

export default Index;
