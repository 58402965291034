import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="32" height="36" viewBox="0 0 32 36" fill="none" {...props} >
            <path d="M15.75 18C20.7211 18 24.75 13.9711 24.75 9C24.75 4.02891 20.7211 0 15.75 0C10.7789 0 6.75 4.02891 6.75 9C6.75 13.9711 10.7789 18 15.75 18ZM22.05 20.25H20.8758C19.3148 20.9672 17.5781 21.375 15.75 21.375C13.9219 21.375 12.1922 20.9672 10.6242 20.25H9.45C4.23281 20.25 0 24.4828 0 29.7V32.625C0 34.4883 1.51172 36 3.375 36H28.125C29.9883 36 31.5 34.4883 31.5 32.625V29.7C31.5 24.4828 27.2672 20.25 22.05 20.25Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
