import {createGlobalStyle} from 'styled-components';
import {P4PTheme} from '../theme';
import OpenSansRegular from '../assets/fonts/OpenSans-Regular.ttf'
import OpenSansSemiBold from '../assets/fonts/OpenSans-SemiBold.ttf'

declare module 'styled-components' {
    export interface DefaultTheme extends P4PTheme {
    }
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "OpenSans";
    src: url("${OpenSansRegular}") format('truetype');
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "OpenSans";
    src: url("${OpenSansSemiBold}") format('truetype');
    font-style: normal;
    font-weight: 600;
  }
  * {
    font-family: 'OpenSans', sans-serif;
    transition: all .25s ease;
    font-style: normal;
    color: ${({theme}) => theme.colors.text};
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background: ${({theme}) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #root {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .app {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .open-mobile-menu {
    height: 100vh;
    overflow: hidden;
  }

`;

export default GlobalStyle;
