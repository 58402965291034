import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="29" height="29" viewBox="0 0 29 29" fill="none" {...props} >
            <path d="M26.6192 7.23218C25.7363 7.62428 24.7879 7.88813 23.7925 8.00715C24.8091 7.39824 25.5887 6.43415 25.9559 5.28461C25.0057 5.84831 23.9521 6.25793 22.8302 6.47935C21.9325 5.52264 20.6538 4.9248 19.2386 4.9248C16.5207 4.9248 14.3176 7.12885 14.3176 9.84585C14.3176 10.2315 14.3618 10.6079 14.4449 10.9668C10.3551 10.762 6.72933 8.80241 4.3011 5.82432C3.87855 6.55131 3.63592 7.3964 3.63592 8.2996C3.63592 10.0064 4.50406 11.513 5.82428 12.3949C5.01794 12.3691 4.25866 12.1477 3.59532 11.7796C3.59532 11.8008 3.59532 11.8202 3.59532 11.8414C3.59532 14.2263 5.29103 16.2153 7.54305 16.6665C7.13065 16.779 6.69519 16.839 6.2459 16.839C5.92945 16.839 5.62039 16.8076 5.32055 16.7514C5.94698 18.7063 7.76446 20.1299 9.91777 20.1695C8.23406 21.4897 6.11212 22.2767 3.80567 22.2767C3.40896 22.2767 3.01686 22.2536 2.63123 22.2075C4.80944 23.6034 7.39543 24.418 10.1752 24.418C19.2275 24.418 24.1763 16.9193 24.1763 10.416C24.1763 10.2029 24.1716 9.99069 24.1624 9.77942C25.1247 9.08472 25.9596 8.21842 26.6192 7.23218Z" fill="#7053F6"/>
        </Svg>
    );

export default Icon;
